.mainbox {
    width           : 15vh;
    height          : 15vh;
    background-color: azure;
    border-width    : 1px;
    border-color    : darkgray;
    text-align      : center;
    align-items     : center;
    display         : inline-block;
}

.gap {
    height: 2vh;
    color : white;
}

.logo {
    width : 80px;
    height: 80px;

}

@media (max-width: 1170px) {
    .h-100 {
        margin-top  : 20px !important;
        height      : 80% !important;
        width       : 80% !important;
        display     : block !important;
        margin-left : auto !important;
        margin-right: auto !important;
        box-shadow  : 0px -2px 10px #ccc;
    }

    .row-cols-md-3 {
        max-width : 80% !important;
        max-height: 30vh !important;

    }

    .description {
        font-size : 10pt !important;
        max-height: 40%;
    }
}

@media (min-width: 1170px) {
    .h-100 {
        margin-top : 20px !important;
        max-height : 50vh !important;
        max-width  : 80% !important;
        margin-left: 2px !important;
        box-shadow : 0px -2px 10px #ccc;
    }

    .row-cols-md-3 {
        max-width : 90% !important;
        max-height: 30vh !important;
    }

    .description {
        font-size : 1vw !important;
        max-height: 60%;
    }
}

.dropdownlist {
    border-color    : rgb(25, 126, 141) !important;
    background-color: rgb(3, 144, 166) !important;
    border-width    : 1px !important;
}

.cardstable {
    display     : flex;
    align-items : center;
    margin-right: 0;
    width       : 100% !important;
}

.refreshbtn {
    height        : 2vh !important;
    vertical-align: top !important;
    background    : transparent;
    border-width  : 0;

}

.clearbtn {
    height        : 2vh !important;
    vertical-align: top !important;
    background    : transparent;
    border-width  : 0;

}

.sub_button {
    background-color: rgb(3, 144, 166) !important;
    border-color    : black !important;
    height          : 5vh !important;
}

.sub_button_subscribed {
    background-color: rgb(2, 144, 38) !important;
    border-color    : black !important;
    height          : 5vh !important;
}

.sub_button_unsubscribed {
    background-color: rgb(176, 2, 2) !important;
    border-color    : black !important;
    height          : 5vh !important;
}

.sub_button_disabled {
    background-color: rgb(175, 175, 175) !important;
    border-color    : black !important;
    height          : 5vh !important;
}

.cardtitle {
    font-weight    : 800;
    text-decoration: underline;
}

.cardimage {
    max-height: 30%;

}

.row-cols-1 {
    margin-left : auto !important;
    margin-right: auto !important;
}

@media (min-width: 1170px) {
    .mlogo {
        margin-left: 20px !important;
    }
}

@media (max-width: 1170px) {
    .mlogo {
        display     : block !important;
        margin-left : auto !important;
        margin-right: auto !important;
    }
}

.selecteditem{
    font-style: italic;
    font-size: smaller;
    color: darkgray;
}