.mlogo {

    display    : block;
    max-width  : 200px;
    max-height : 50px;
    width      : auto;
    height     : auto;
    margin-left: 10px;

}

.wait {
    max-width : 200px;
    max-height: 200px;
    position  : fixed;
    /* or absolute */
    top       : 40%;
    left      : 40%;
}

.wait-card {
    max-width : 200px;
    max-height: 200px;
    position  : absolute;
    top       : 35%;
    left      : 30%;
}

.subalert {
    max-width : 200px;
    max-height: 200px;
    margin-top: 50px;
}

.active {
    color          : rgba(3, 143, 166, 0.95) !important;
    text-decoration: underline !important;

}



@media (max-width: 1170px) {

    .main {
        max-width: 100%;
    }

    .brandlogo {
        display     : flex !important;
        margin-left : auto !important;
        margin-right: auto !important;

    }

    .topPanel {
        width        : 5%;
        margin-left  : 0;
        margin-right : auto;
        padding      : 10px 30px 8px;
        border-radius: 50px;
        box-shadow   : 2px 2px 5px #ccc;
    }

    .welcomeNote {
        width: 100vh !important;
        margin-left : 4vh;
        margin-right: 2vh;
    }

    .welcome-text {
        font-size: x-small;
    }

    .navbar {
        color       : transparent !important;
        border-color: rgba(3, 143, 166, 0.95) !important;

    }

    .titles_mob{
        text-align: center;
        max-width: 60vh;
    }

    .mainpage_table{
        max-width: 100% !important;
        margin-left: 2vh;
        font-size: 0.5vw !important;
    }
    
    
    .mainpage_table_thirty{
        max-width: 20vh !important;
    }

}

@media (min-width: 1170px) {
    .navbar {
        color        : transparent !important;
        border-color : rgba(3, 143, 166, 0.95) !important;
        border-radius: 0 0 999px 0 !important;
        box-shadow   : 2px 2px 5px rgba(3, 143, 166, 0.95);
    }

    .topPanel {
        width        : 31%;
        margin-left  : auto;
        margin-right : auto;
        padding      : 10px 30px 8px;
        border-radius: 50px;
        box-shadow   : 2px 2px 5px #ccc;
    }

    .welcomeNote {
        width: 100vh !important;
        margin-left : 20vh;
        margin-right: 20vh;
    }
}

.signout {
    background-color: rgba(3, 143, 166, 0.95);
    border-color    : white;
    color           : white;
    font-family     : "Worldly", sans-serif;
    position        : fixed;
    font-size       : 1vw;
    text-align      : center;
    min-width       : 120px;
    padding         : 10px 30px 8px;
    border-radius   : 50px;
    text-decoration : none !important;
    margin-left     : 200px;
    border-width    : 1px;
    right           : 15vh;

}

.signout:hover {
    background-color: rgba(1, 176, 203, 0.95);
    transition      : all 0.3s ease-out;
}


th,
td {
    padding: 15px;
}

.main {
    width: 100% !important;
}

/* COLLAPS BAR */

.collapsbarbtn {
    margin-top    : -2vh !important;
    border-top    : transparent;
    border-width  : 0px;
    border-radius : 0 0 10px 10px !important;
    box-shadow    : 2px 1px 5px rgba(5, 136, 156, 0.838);
    width         : 10vh;
    height        : 2vh;
    vertical-align: middle;

}

.collapsbartable {
    width          : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
    text-align     : center;
    vertical-align : middle;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}


/* PERSONAL SETTINGS */
.settingtable {
    border-radius: 50px 50px 50px 50px !important;
    box-shadow   : 2px 1px 5px rgba(5, 136, 156, 0.838);
    border-width : 1px;
    margin-top   : 30px;
    width        : 50vh;
    height       : 90%;
}

.settingcell {
    width: 30vh;
}

.settingcellformat {
    border-radius: 5px 5px 5px 5px !important;
    border-color : lightgray !important;
    border-width : 1px;
    border-style : solid;
    height       : 4vh;
}

.settingphone {
    height: 4vh;
}

.settingbuttons {
    border-radius   : 5px 5px 5px 5px !important;
    border-style    : solid;
    border-width    : 1px;
    width           : 8vh;
    height          : 4vh;
    background-color: rgba(186, 186, 186, 0.95);
}

.settingbuttons:hover {
    background-color: rgba(111, 185, 196, 0.95);
}

/* ABOUT US */
.gap {
    height: 2vh;
    color : white;
}

.about-text {
    width      : 120vh;
    line-height: 20pt;
    margin     : 15px 0;
    margin-left: 20px;
    text-align : justify;
    font-size  : 14pt;
    font-family: "Worldly", sans-serif;
    color      : rgb(77, 78, 78);
    font-weight: lighter;
}

.about-titles{
    color : 2px 1px 5px rgba(5, 136, 156, 0.838);
    
}

.about-text-body{
    text-align:center;
    font-size:17pt;
    font-weight:lighter;
    width      : 100vh;
    line-height: 20pt;
    margin     : 15px 0;
    margin-left: 20px;
    text-align: center;
    
    font-family: "Worldly", sans-serif;
    color      : rgb(77, 78, 78);
}
/* CONTACT US */
.contactus {
    box-shadow  : 2px 1px 5px rgba(5, 136, 156, 0.838);
    margin-top  : 30px;
    margin-left : 20vh;
    margin-right: 20vh;
    width       : 50vh;
    height      : 70vh;
}

.contactform {
    margin-top  : 2vh;
    margin-left : 2vh;
    margin-right: 2vh;
}

.contactformlabels {
    text-align : left;
    font-weight: bold;
}

.mainpage_table{
    max-width: 100% !important;
    margin-left: 2vh;
}


.mainpage_table_thirty{
    max-width: 100vh !important;
}

.border{
    border-color: black;
    border-style: dashed;
    border-width: 15px;
}

.team_members_style{
    font-weight:200;
    margin-left: 10px;
    text-align: left;
}