.pageHeader {
    background-color: lightgrey;
    height          : 5vh;
    width           : 100vh;
    font-size       : x-large;
    display         : flex;
    font-weight     : bolder;
    margin          : 0;
    line-height     : 5vh;
}

.pageFooter {
    background-color: rgb(241, 241, 241);
    height          : 5vh;
    width           : 100vh;
    font-size       : large;
    font-weight     : bolder;
    margin          : 0;
    line-height     : 5vh;
}

.gap {
    height: 2vh;
    color : white;
}

.gap-h {
    width: 2vh;
}

.textblock {
    height          : 25vh;
    width           : 100vh;
    background-color: #c5f2f7;
    border-radius   : 25px;
    text-align      : left;
    font-size       : x-large;
}

.slider-texblock {
    width      : 50vh;
    margin-left: 25vh;
}

.home_textblock {
    background-color: #a3c7ff;
}

.dataprocess_textblock {
    background-color: #f9fddd;
}

.header-panel {
    background-color: rgba(3, 143, 166, 0.95);
    width           : 80%;
    height          : 20hv;
    border-radius   : 0 999px 999px 0 !important;
}

.header-left {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;

}

.header-logo {
    width : 20%;
    height: 50%;
}

.header-welcome {
    font-family   : "Worldly", sans-serif;
    font-size     : 1.2vw;
    text-align    : left;
    width         : 50%;
    display       : flex;
    flex-direction: row;
    column-gap    : 20px;
    color         : white;
    align-items   : center;

}

.header-right {
    margin-right   : 20px;
    margin-left    : 20px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
}

.login {
    display         : flex;
    justify-content : center;
    align-items     : center;
    height          : 100vh;
    background-image: url('../images/signinbackground.jpg');
    Width           : 100%;
    background-size : cover;
    background-color: blue;
}



.main {
    display       : flex;
    flex-direction: row;
    height        : 85vh;

}



@media (max-width: 1170px) {
    .footer-panel {
        float           : right;
        background-color: rgba(3, 143, 166, 0.95);
        width           : 40vh;
        height          : 5vh;
        color           : white;
        border-radius   : 999px 0 0 999px !important;
        align-items     : flex-end !important;
        text-align      : center;
        position        : fixed;
        bottom          : 10px;
        right           : 0;
        padding         : 1vh;
        font-size       : x-small;
    }
    .authenticator {
        display         :contents ;
        justify-content : center;
        align-items     : center;
        vertical-align  : middle;
        background-image: url('../images/signinbackground.jpg');
        height          : 100vh;
        background-size : cover;
        width: 100%;
    }
    .mainpage{
        max-width: 97%;
    }

}

@media (min-width: 1170px) {
    .footer-panel {
        float           : right;
        background-color: rgba(3, 143, 166, 0.95);
        width           : 40vh;
        height          : 5vh;
        color           : white;
        border-radius   : 999px 0 0 999px !important;
        align-items     : flex-end !important;
        text-align      : center;
        position        : fixed;
        bottom          : 10px;
        right           : 0;
        padding         : 1vh;
    }
    .authenticator {
        display         : flex;
        justify-content : center;
        align-items     : center;
        vertical-align  : middle;
        background-image: url('../images/signinbackground.jpg');
        height          : 100vh;
        background-size : cover;
    }
    .mainpage{
        max-width: 95%;
    }



}





