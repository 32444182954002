.welcome-page {
    font-size  : 1vw;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
    height             : 80vh;
    width              : 100vw;
    display            : flex;
    justify-content    : center;
    align-items        : center;
    background-color   : rgb(255, 255, 255);
    background-size    : 70vh;
    background-position: 55vh;
    background-image   : url('../images/line.png');
    background-repeat  : no-repeat;
}


.logo {
    margin-right: 200pt;
}



.items-box {
    width      : 100vh;
    position   : absolute;
    align-items: center;
    text-align : center;
}

.gap {
    height: 2vh;
    color : black;
}

.welcome-items {
    color    : rgba(3, 143, 166, 0.95);
    font-size: 2vw;
}

@media (max-width: 1170px) {
    .buttons {
        position        : relative;
        align-items     : center;
        background-color: #7cc8d4;
        color           : white;
        font-family     : "Open Sans", sans-serif;
        display         : inline-block;
        font-size       : 5vw;
        text-align      : center;
        min-width       : 80px;
        padding         : 5px 2px 5px;
        border-radius   : 25px;
        border-width    : 0;
        text-decoration : none !important;
        height          : 8vh;
        width           : 25vh;

    }
    .logos {
        width : 40%;
        height: 20%;
    }
    .buttons:hover {
        background-color: rgb(0, 84, 97);
        color           : white;
    }
}

@media (min-width: 1170px) {
    .buttons {
        position        : relative;
        align-items     : center;
        background-color: #7cc8d4;
        color           : white;
        font-family     : "Open Sans", sans-serif;
        display         : inline-block;
        font-size       : 2vw;
        text-align      : center;
        min-width       : 80px;
        padding         : 5px 2px 5px;
        border-radius   : 25px;
        border-width    : 0;
        text-decoration : none !important;
        height          : 8vh;
        width           : 25vh;

    }
    .logos {
        width : 15%;
        height: 7.5%;
    }
    .buttons:hover {
        background-color: rgb(0, 84, 97);
        color           : white;
    }
}


.footer {
    float           : right;
    background-color: rgba(3, 143, 166, 0.95);
    width           : 45vh;
    height          : 5vh;
    color           : white;
    border-radius   : 999px 0 0 999px !important;
    align-items     : flex-end;
    text-align      : center;
    padding         : 1vh;
}