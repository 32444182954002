.CircularProgressbar-trail {
    stroke: gray;
  }
  .CircularProgressbar-text {
    fill: yellow;
  }
  .CircularProgressbar-background {
    fill: green;
  }

  .chartStyle{
    width: 20vh;
    height: 2vh;
    margin-left: 10vh;
    margin-top: 5vh;
  }